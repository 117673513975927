import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Tabs, Tab, Button } from 'react-bootstrap';
import { Helpers } from '@ai4/records-management';
import { useDataRequest } from '@ai4/data-request';
import { GET_CARTELLA } from './gql/cartella';
import Crud from 'src/@bootstrap/components/Crud/Crud';
import { ConfigContext } from 'src/@bootstrap/Config';
import EditCartellaDettaglioDimissioni from './Dimissioni';
import EditSchedaPresaInCarico from './SchedaPresaInCarico';
import { useIsSGDT } from '../sgdt/SGDT';
import CartellaProgettiIndividuali from '../sgdt/CartellaProgettiIndividuali';

const CartellaDettaglio = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const defaultSection = 'valutazioni';
    const [section, setSection] = useState<string>(defaultSection);
    const { getEnv, hasFeature } = useContext(ConfigContext);
    const { useQuery } = useDataRequest();
    const isSGDT = useIsSGDT();

    useEffect(() => {
        const hash = Helpers.getHash();
        // sample http://localhost:3000/cartelle/dettaglio/18b20000-5d40-0015-2f3d-08db4ae0cf2e#%5B%7B%22tab%22:%22affidamenti%22%7D,%7B%22entity%22:%22affidamenti%22,%22action%22:%22edit%22,%22uniqueId%22:%225bc2bd0e-1078-4ac4-a954-991d457b1d9d%22%7D%5D
        if (hash && typeof hash !== 'string') {
            const tab = hash.find(h => h.tab);
            if (tab) {
                setSection(tab.tab);
            }
        }
    }, []);

    const query = useQuery(GET_CARTELLA, {
        variables: {
            id,
        }
    });
    const data = _get(query.data, 'cartellaSocioSanitariaQuery.cartelle.list', []);
    const record = useMemo(() => {
        return data.length > 0 ? data[0] : {};
    }, [data]);

    const subProps = {
        options: {
            parent: record,
            parent_type: 'cartella',
            cartellaUniqueId: id,
        }
    }

    if (!record || !record.uniqueId) return null;
    const hasProgetti = _get(record, 'progetti', []).length > 0;
    return <div>
        <div className="d-flex flex-row align-items-center mb-4">
            <button type="button" className="btn btn-link" onClick={() => navigate('/cartelle/ricerca')}>
                <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
            </button>
            <h1>Cartella {record.codice} di {_get(record, 'assistito.datiAnagrafici.nome')} {_get(record, 'assistito.datiAnagrafici.cognome')}</h1>
        </div>
        <Tabs
            defaultActiveKey={defaultSection}
            activeKey={section}
            className="mb-3"
            onSelect={(k) => setSection(k || defaultSection)}
        >
            {hasFeature("cartelle_schedaPresaInCarico") && <Tab eventKey="schedaPresaInCarico" title="Presa in carico">
                {section === 'schedaPresaInCarico' && <EditSchedaPresaInCarico parent={record} onBack={() => setSection('progetti')} />}
            </Tab>}
            <Tab eventKey="valutazioni" title="Valutazioni">
                {section === 'valutazioni' && <Crud module='cartelle' entity='valutazioni' path='cartelle/sub/valutazioni' {...subProps} />}
            </Tab>
            {isSGDT && <Tab eventKey="sgdt" title="PI">
                {section === 'sgdt' && <CartellaProgettiIndividuali cartellaId={id!} />}
            </Tab>}
            <Tab eventKey="progetti" title={getEnv('PAI_LABEL')}>
                {section === 'progetti' && (
                    <Crud
                        module='cartelle'
                        entity='progetti'
                        path='cartelle/progetti'
                        {...subProps}
                        options={{ ...subProps.options, canCreate: false }}
                    />
                )}
            </Tab>

            {/*
            <Tab eventKey="affidamenti" title="Titoli">
                {section === 'affidamenti' && <Crud module='cartelle' entity='affidamenti' path='cartelle/affidamenti' options={{ ...subProps.options, canCreate: false }} />}
            </Tab>
            */}
            {hasProgetti && <Tab eventKey="accessiDiretti" title="Accessi">
                {section === 'accessiDiretti' && <Crud module='cartelle' entity='accessiDiretti' path='cartelle/sub/accessiDiretti' {...subProps} />}
            </Tab>}
            {hasProgetti && <Tab eventKey="sospensioni" title="Sospensioni">
                {section === 'sospensioni' && <Crud module='cartelle' entity='sospensioni' path='cartelle/sub/sospensioni' {...subProps} />}
            </Tab>}
            <Tab eventKey="diario" title="Diario">
                {section === 'diario' && <Crud module='cartelle' entity='diario' path='cartelle/diario' {...subProps} />}
            </Tab>
            {hasFeature("cartelle_dimissioni") && <Tab eventKey="dimissioni" title="Dimissioni">
                {section === 'dimissioni' && <EditCartellaDettaglioDimissioni parent={record} onBack={() => setSection('progetti')} />}
            </Tab>}
        </Tabs>
    </div>
}


export default CartellaDettaglio;